export async function setupDeviceStatusMonitor(visitorId) {
    try {
        Echo.private(`device-status.${visitorId}`)
            .listen('DeviceStatusChangedEvent', async (e) => {
                console.log("Device status changed: ", e.status);

                if (e.status === "disassociated") {
                    await handleDeviceDisassociation(visitorId);
                }
            });
    } catch (error) {
        console.error("Erro ao configurar Echo para 'device-status' channel:", error);
    }
}

async function handleDeviceDisassociation(visitorId) {
    const token = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch('/logout', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': token,
        },
        body: JSON.stringify({
            device_fingerprint_id: visitorId
        }),
        credentials: 'same-origin'
    });

    if (response.ok) {
        window.location.href = '/login';
    }
}
